import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Newsletter from '../components/footer/newsletter'

export default ({data}) => {
  return (
    <Layout>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug} />
      <Hero mode="contact" title={data.wordpressPage.acf.header_text} content={data.wordpressPage.acf.header_content} button={{title:'',url:'',target:''}} />
      <div className="contact">
      </div>
      <Newsletter />
    </Layout>
  )
}

export const pageQuery = graphql`
query contactQuery {
  wordpressPage(wordpress_id: {eq: 278}) {
    acf {
      header_content
      header_text
    }
    content
    title
    slug
    yoast_meta {
      name
      property
    }
    yoast_title
  }
}
`